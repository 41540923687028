.header-button {
  cursor: pointer;
}

.header-title {
  margin: 0;
  font-size: 1.2rem;
}

.action-content {
  text-align: center;
  cursor: pointer;
  width: 100%;
  outline: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.action-content .material-icons {
  display: inline-block;
  color: #1D19AC;
}
.action-content .action-name {
  margin-left: 0.375rem;
}

.empty-state {
  margin: 1rem 0;
  font-size: 1.1rem;
  line-height: 1.2;
  color: #777;
  text-align: center;
}
.empty-state i {
  margin-top: 2rem;
  font-size: 4rem;
}

.loader,
.loader:before,
.loader:after {
  background: #1D19AC;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}

.loader {
  color: #1D19AC;
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: "";
}

.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.loader:after {
  left: 1.5em;
}

@-webkit-keyframes load1 {
  0%, 80%, 100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%, 80%, 100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
html,
body {
  margin: 0;
  font-size: 16px;
}

body {
  margin: 0;
}

@media (max-width: 640px) {
  html,
  body {
    font-size: 14px;
  }
}
@font-face {
  font-family: GothamRounded-Bold;
  src: url("fonts/GothamRounded-Bold.otf") format("opentype");
}
@font-face {
  font-family: GothamRounded-Book;
  src: url("fonts/GothamRounded-Book.otf") format("opentype");
}
@font-face {
  font-family: GothamRounded-Light;
  src: url("fonts/GothamRounded-Light.otf") format("opentype");
}
.pac-container {
  z-index: 2000;
}

