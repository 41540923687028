.header-main {
  // padding: 1rem 0;
}

.header-button {
  cursor: pointer;
}

.header-title {
  margin: 0;
  font-size: 1.2rem;
}
