@import 'modules/layout/styles/layoutVars';

@import 'modules/layout/styles/header';
@import 'modules/layout/styles/content';
@import 'modules/layout/styles/loader';

html,
body {
  margin: 0;
  font-size: 16px;
}

body {
  margin: 0;
}

@media (max-width: $media-phone) {
  html,
  body {
    font-size: 14px;
  }
}

@font-face {
  font-family: GothamRounded-Bold;
  src: url('fonts/GothamRounded-Bold.otf') format('opentype');
}

@font-face {
  font-family: GothamRounded-Book;
  src: url("fonts/GothamRounded-Book.otf") format("opentype"); }

@font-face {
  font-family: GothamRounded-Light;
  src: url('fonts/GothamRounded-Light.otf') format('opentype');
}

// Google maps search box
.pac-container {
  z-index: 2000;
}
