@import './colors';
@import './layoutVars';



.action-content {
  text-align: center;
  cursor: pointer;
  width: 100%;
  outline: none;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .material-icons {
    display: inline-block;
    color: $primary;
  }

  .action-name {
    margin-left: 0.375rem;
  }
}

.empty-state {
  margin: 1rem 0;
  font-size: 1.1rem;
  line-height: 1.2;
  color: $gray-dark;
  text-align: center;

  i {
    margin-top: 2rem;
    font-size: 4rem;
  }
}